import React, { useState, useEffect } from "react"
import algoliasearch from "algoliasearch/lite"
import {
  connectStats,
  RefinementList,
  InstantSearch,
  Configure,
  connectHits,
  connectInfiniteHits,
  connectStateResults,
  connectGeoSearch,
} from "react-instantsearch-dom"
import {
  isTablet,
  isIPad13,
  isIOS13,
  isMobile,
  isMobileOnly,
  isDesktop,
  browserName,
} from "react-device-detect"
// import Loader from "../components/loader";
import { Col, Container, Row, Form } from "react-bootstrap"
import $ from "jquery"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import markerImageSrc from "../images/map-marker.svg"
import NoImage from "../images/cubitt-no-image.png"
import FilterBlock from "../components/SearchResult/FilterBlock/FilterBlockNewHomes"
import SearchList from "../components/SearchResult/SearchList/SearchListNewHomes"
import "../components/SearchResult/SearchResult.scss"
import { NEW_HOMES_SEARCH_PAGE_URL } from "../components/common/site/constants"
import LoadExternalScript from "../components/utils/load-external-script"
import classNames from "classnames"

import Loadable from "@loadable/component"
// const MapResultsDesktop = Loadable(() => import("../components/map/search-results-new-homes-locrating-map"));
import MapResultsDesktop from "../components/map/search-results-os-map"
import "../components/map/osmap.scss"
import * as L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
//import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import "leaflet.markercluster"

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index_name = process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENT_INDEX_NAME

const CustomInfiniteHits = connectHits(SearchList)

// No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
  return (
    <>
      {nbHits === 0 && (
        <Container>
          <Row>
            <Col lg={12} className="text-center no-properties-text mb-5">
              <h4>
                Unfortunately, we do not currently have any properties that
                match your search criteria.
              </h4>
              <a onClick={() => {
                localStorage.setItem("searchValue", "")
                localStorage.setItem("searchValueFull", "")
                localStorage.setItem("searchValueFullName", "")
              }} href={NEW_HOMES_SEARCH_PAGE_URL.alias} className="reset-link">
                Reset the search
              </a>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

const CustomNoStats = connectStats(NoStats)
// No Results

//map section
class GeoSearch extends React.Component {
  isUserInteraction = true
  markers = []
  autopan = false;

  componentDidMount() {
    const { refine } = this.props
    if (typeof window !== "undefined") {

      L.Control.include({
        _refocusOnMap: L.Util.falseFn // Do nothing. when click zoom icon avoid map element focus
      });

      if (isTablet || isMobile) {
        this.instance = new L.map(this.el, { gestureHandling: true, zoom: 21 })
      } else {
        this.instance = new L.map(this.el, { gestureHandling: false, zoom: 21 })
      }

      new L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png", {
        attribution:
          '&copy; <a class="mapcopyright" href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors',
      }).addTo(this.instance)

      window.addEventListener('resize', this.instance.invalidateSize())

      this.instance.on("autopanstart", (e) => {
        this.autopan = true
      })

      this.instance.on("moveend", () => {
        if (this.autopan == false) {
          if (this.isUserInteraction) {
            const ne = this.instance.getBounds().getNorthEast()
            const sw = this.instance.getBounds().getSouthWest()
            if (ne.lat != sw.lat && ne.lng != sw.lng && ne.lng < 180) {
              refine({
                northEast: { lat: ne.lat, lng: ne.lng },
                southWest: { lat: sw.lat, lng: sw.lng },
              })
              // this.props.mapdraggedbyuser(true);
              //localStorage.setItem('searchValue', '');
            }
          }
        }
        this.autopan = false
      })

    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener('resize', this.instance.invalidateSize())
    }
  }

  getButtonRestElem = () => {
    return this.buttonrest;
  }

  componentDidUpdate(prevProps) {
    const { refine } = this.props
    if (prevProps.enablecluster !== this.props.enablecluster) {
      window.removeEventListener('resize', this.instance.invalidateSize())
    }
    if (prevProps.enablecluster == "") {
      window.removeEventListener('resize', this.instance.invalidateSize())
    }
    const { hits, currentRefinement, position } = this.props
    if (typeof window !== "undefined") {
      window.removeEventListener('resize', this.instance.invalidateSize())
    }
    this.markers.forEach(marker => marker.remove())
    if (typeof window !== "undefined") {
      //const markersCluster = new L.MarkerClusterGroup();
      if (this.props?.enablecluster == "true") {
        var markersCluster = new L.markerClusterGroup({
          spiderfyOnMaxZoom: true,
          showCoverageOnHover: true,
          zoomToBoundsOnClick: true,
        })
      }
      var myIcon = new L.icon({
        iconUrl: markerImageSrc,
        shadowUrl: "",

        iconSize: [22, 23], // size of the icon
        shadowSize: [22, 23], // size of the shadow
        // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        // shadowAnchor: [4, 62], // the same for the shadow
        popupAnchor: [-3, -6], // point from which the popup should open relative to the iconAnchor
      })
      //var bounds = new L.latLngBounds() // Instantiate LatLngBounds object
      if (this.props?.enablecluster == "false") {
        //this.instance.invalidateSize();
        this.markers = hits.map((hit) => {
          //bounds.extend([hit._geoloc.lat, hit._geoloc.lng])// for fitbounds
          return new L.marker([hit._geoloc.lat, hit._geoloc.lng], { icon: myIcon }).addTo(this.instance).on(
            "click",
            function () {
              var target = $("#" + hit.objectID)
              if (target.length) {
                $("html,body").animate(
                  { scrollTop: target.offset().top - 100 },
                  10
                )
                $(target).addClass("active").siblings().removeClass("active")
              }
            }
          )
        })
        //if(bounds?._northEast?.lat > 0){
        //this.instance.fitBounds(bounds)
        //}
      }
      if (this.props?.enablecluster == "true") {
        //this.instance.invalidateSize();
        this.markers = hits.map((hit) => {
          //bounds.extend([hit._geoloc.lat, hit._geoloc.lng])// for fitbounds
          let _price = hit.price?.toLocaleString();
          let _html = hit.development_title;
          let _title = hit.title
          var image_url = ''
          if (hit?.images1) {
            image_url = hit?.images1?.images_src[0];

          } else {
            image_url = NoImage
          }

          if (hit.min_price && hit.max_price) {
            if (hit.min_price == hit.max_price) {
              _price = "Price £" + hit.min_price ? hit.min_price.toLocaleString() : 0
            }
            else {
              _price = hit.price_prefix + "£" + hit.min_price ? hit.min_price.toLocaleString() : 0 + "-" + "£" + hit.max_price ? hit.max_price.toLocaleString() : 0
            }
          }

          var url = '/new-home-for-sale/' + hit.slug + '-' + hit.objectID
          // if (hit.search_type === "sales") {
          //     url = '/property-for-sale/' + hit.slug + '-' + hit.crm_id
          // }
          // else if (hit.search_type === "lettings") {
          //     url = '/property-to-rent/' + hit.slug + '-' + hit.crm_id
          // }
          return markersCluster.addLayer(new L.marker([hit._geoloc.lat, hit._geoloc.lng], { icon: myIcon }).bindPopup('<div style="width: 250px;margin:-14px -25px -14px -21px;"><a href=' + process.env.GATSBY_SITE_URL + url + ' ><img src=' + image_url + ' style="width: 100%;height: 200px;object-fit: cover;" /></a><div style="padding: 20px;width:100%;"><p style="font-size:16px;color: #034051;padding-top:0px;font-family:GothamBook;margin-top: 10px;margin-bottom: 10px;">' + _html + '</p><p style="font-family:GothamBook;font-size: 16px;color: #005670;margin-top: 0;margin-bottom: 15px;font-weight: 600;">£' + _price + '</p><a style="font-family:GothamBook;text-decoration:underline;color: #034051;" target="_parent" href=' + process.env.GATSBY_SITE_URL + url + ' >View Property Details</a></div></div>').openPopup())
        })
        //if(bounds?._northEast?.lat != ""){
        //this.instance.fitBounds(bounds)
        //}
      }
      //this.instance.addLayer(markersCluster)
      if (this.props?.enablecluster == "true") {
        markersCluster.addTo(this.instance);
      }

      this.isUserInteraction = false
      if (!currentRefinement && this.markers.length) {
        //if (this.props?.enablecluster == "false") {
          this.instance.flyToBounds(new L.featureGroup(this.markers).getBounds(), {
            animate: (this.props?.enablecluster == "true") ? true : false, duration: 1,
          })
        //}
      } else if (!currentRefinement) {
        this.instance.setView(
          position || {
            lat: 51.1950576,
            lng: 0.1822206,
          },
          15,
          {
            animate: (this.props?.enablecluster == "true") ? true : false, duration: 1,
          }
        )
      }
    }
    this.isUserInteraction = true
    if (this.props?.enablecluster == "true") {
      //this.isUserInteraction = true
      this.instance.on("resize", (e) => {
        if (prevProps.enablecluster == this.props.enablecluster) {
          refine();
          this.props.mapdraggedbyuser(false);
        }
      })
      this.instance.on("mousedown mouseup touchend touchstart zoomanim", (e) => {
        if (prevProps.enablecluster == this.props.enablecluster) {
          this.props.mapdraggedbyuser(true);
        }
      })
    } else {
      this.instance.on("mousedown mouseup touchend touchstart zoomanim", (e) => {
          this.props.mapdraggedbyuser(true);
      })
    }
  }

  render() {
    const { currentRefinement, refine } = this.props
    return (
      <div id="location-map" className={`${this.props?.enablecluster == "true" ? 'location-map' : ''}`}>
        <div ref={c => (this.el = c)} />
        {Boolean(currentRefinement) && (
          <button id="mymapclearBtn" ref={this.props.buttonrest} className="maprefinebutton" onClick={() => refine()}>Clear map refinement</button>
        )}
      </div>
    )
  }
}

const CustomGeoSearch = connectGeoSearch(GeoSearch)

// map section ends here

const SearchTemplate = props => {
  const [sold, setIncludeSold] = React.useState(false)
  const [scroll, setScroll] = useState(false)
  const [underOffer, setIncludeUnderOffer] = React.useState(false)
  const [scrollToTopBtn, setScrollToTop] = useState(false)
  const buttonrest = React.createRef();
  const [mapdragbyuser, setMapdragbyuser] = React.useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1)
      if (window.pageYOffset > 500) {
        setScrollToTop(true)
      } else {
        setScrollToTop(false)
      }
    })

    // iphone zoom issue when type a text in input field
    $("head").append(
      '<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">'
    )
    $("body").addClass("search-template")
    // iphone zoom issue when type a textin input field
  }, [])

  const includeSold = value => {
    setIncludeSold(value)
  }

  const mapdraggedbyuser = value => {
    setMapdragbyuser(value)
  }

  const includeUnderOffer = value => {
    setIncludeUnderOffer(value)
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    })
  }
  let realUser = true
  if( process.env.GATSBY_BlOCK_ALGOLIA_BOT_REQUEST == "true" ) { // only when ENV enabled
    if( typeof window !== 'undefined' ) {
      if ( (navigator.userAgent).indexOf("prerender") > 0 ){
        realUser = false
      }
    }
  }
  return (
    <div
      className={`${scroll
        ? "wrapper search-result-page scroll new-home-search-result"
        : "wrapper search-result-page new-home-search-result"
        }`}
    >
      <Header type="results" />
      {/* <LoadExternalScript
                src={locRatingScriptsJs}
                // async="true"
                defer="true"
                loadScript={renderMap}
            // appendScriptTo="head"
            /> */}
      {realUser &&
      <InstantSearch
        indexName={index_name}
        searchClient={client}
        onSearchStateChange={state => {
          props.changeStateAndUrl(state)
        }}
        hits
      >
        <Configure aroundLatLng={props.aroundLatLng} aroundRadius={props.aroundRadius} getRankingInfo={props.getRankingInfo} {...props.algoliaConfig} />
        <FilterBlock
          {...props}
          // setMapview={setMapUrl}
          buttonrest={buttonrest}
          mapdraggedbyuser={mapdraggedbyuser}
          includeSold={includeSold}
          includeUnderOffer={includeUnderOffer}
          mapView={props.mapView}
          searchType={"sales"}
          query={props.query}
          index_name={index_name}
          search_area={props.search_area}
          h1={props.h1}
          off_plan={false}
          sales={true}
          changeRadius={props.changeRadius}
        />
        <div className="d-none">
          {/* <RefinementList
                        attribute="brand_id"
                        defaultRefinement={[process.env.GATSBY_STRAPI_BRAND_ID]}
                    /> */}
          {/* <RefinementList
                        attribute="search_type"
                        defaultRefinement={[props.searchType]}
                    /> */}
          {/* <RefinementList
                            attribute="status"
                            defaultRefinement={getRefinement()}
                        /> */}
          {/* <RefinementList
                        attribute="publish"
                        defaultRefinement={["true"]}
                    /> */}
        </div>
        <div
          className={`search-result-block mobile-view-results-page ${props.mapView ? " d-block" : ""
            }`}
        >
          {props.mapView ?
            <button className="exit-button" onClick={() => { props.changeToListUrl(); sessionStorage.setItem('MainView', 'List'); localStorage.removeItem('map-hits'); }}><i className="arrow-left"></i>Exit Map</button>
            : ''}
          <div className={`search-list-block ${props.mapView ? 'd-none' : ''}`}>
            <CustomInfiniteHits
              {...props}
              mapdragbyuser={mapdragbyuser}
              off_plan={false}
              search_area={props.search_area}
              index_name={index_name}
              sortByIndex={
                props.location.pathname.includes("most-recent")
                  ? index_name + "_most_recent"
                  : index_name
              }
            />
            <CustomNoStats />
          </div>

          <div
            className={`${props.mapView ? "map-box grid-view" : "map-box map-box-listview"} ${1 == 1 ? "mobile-loc-map" : ""
              }`}
            id="res-div-two"
          >
            <div id="map-holder" className={classNames("map")}>
              {props.mapView && (1 == 1) ? (
                <CustomGeoSearch buttonrest={buttonrest} mapdraggedbyuser={mapdraggedbyuser} enablecluster="true" />
              ) : (
                <CustomGeoSearch buttonrest={buttonrest} mapdraggedbyuser={mapdraggedbyuser} enablecluster="false" />
              )}
            </div>
          </div>
        </div>

        {props.mapView && (
          <>
            {(isTablet || isMobile) && (
              <div className="map-configure">
                <Configure hitsPerPage={200} />
              </div>
            )}
            {/* <MapResultsDesktop /> */}
          </>
        )}
      </InstantSearch>
      }
      {scrollToTopBtn && (
        <button onClick={scrollToTop} className="back-to-top">
          <i className="icon-up"></i>
        </button>
      )}
      <Footer 
        searchtype="newhomesales"
        search="searchresults"
        searchPtype={props.property_type}
        popularSearch="propertyresults"
        searchBedroomfield={props.bedrooms}
        Searcharea={props.search_area} />
    </div>
  )
}

export default SearchTemplate
